<template >
  <div >
    <h5 v-if="createAfterTableData.length !== 0" >
      {{ $t('key1003869') }}{{ createAfterTableData.length }}{{ $t('key1003870') }} </h5 >
    <Table border :columns="columns" :data="createAfterTableData" ></Table >
  </div >
</template >
<script >
import { aliasb039bd6e4486415fba0a6b42f8cd95d1 } from '@/customFolder/customVueAlias.js';

export default {
  props: ['data', 'createAfterTableData'],
  data () {
    return {
      columns: [
        {
          type: 'index',
          width: 60,
          align: 'center'
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003871'),
          key: 'pickingGoodsNo'
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002591'),
          key: 'type'
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002542'),
          key: 'warehouseName'
        }
      ]
    };
  },
  created () {},
  computed: {},
  destroyed () {
    this.$parent.$parent.createAfterTableData = [];
  }
};
</script >

<style ></style >
