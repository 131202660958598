<template>
  <Modal width="700" v-model="createPickListModel" class-name="createPickListStyle" @on-visible-change='createPickListChange' :title="$t('key1002563')">
    <div v-if="defaultShow">
      <!--您当前筛选的包裹汇总信息-->
      <div class="createPickList_item">
        <h2 class="title_box">{{ $t('key1006074') }}</h2>
        <Table border :columns="packageSummaryInfoColumns" :data="packageSummaryInfoData" :show-header="false"></Table>
      </div>
      <!--拣货单生成规则-->
      <div class="createPickList_item">
        <h2 class="title_box">{{ $t('key1003872') }}</h2>
        <pickingRules :pickingSetInfo="pickingSetInfo" @updateRulesData="updateRulesData"></pickingRules>
      </div>
      <!--拣货提醒-->
      <div class="createPickList_item">
        <h2 class="title_box">{{ $t('key1003861') }}</h2>
        <Input v-model="picking_text" type="textarea" :autosize="{ minRows: 4}"></Input>
      </div>
      <!--常用标签-->
      <div class="createPickList_item">
        <h2 class="title_box">{{ $t('key1006075') }}</h2>
        <span class="tag_boxs" v-for="(item, index) in countLabels" :key="index" @click="selectTag(item)">{{ item }}</span>
      </div>
    </div>
    <div v-else>
      <successCrtPickList :createAfterTableData="createAfterTableData"></successCrtPickList>
    </div>
    <template #footer>
      <div v-if="defaultShow">
        <Button @click="closeBtn">{{ $t('key1000098') }}</Button>
        <Button type="primary" @click="clickBtn" :disabled="disabledCreateBtn">{{ $t('key1002563') }}</Button>
      </div>
      <div v-else>
        <Button @click="closeBtn">{{ $t('key1000205') }}</Button>
        <Button type="primary" @click="$router.push('/wms/pickList')">{{ $t('key1003873') }}</Button>
      </div>
    </template>
  </Modal>
</template>
<style lang='less' scoped>
.tag_boxs {
  height: 30px;
  line-height: 30px;
  display: inline-block;
  text-align: center;
  color: #333;
  border: 1px solid #ccc;
  padding: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 10px 10px 0;
}
</style>
<script>
import { aliasb039bd6e4486415fba0a6b42f8cd95d1 } from '@/customFolder/customVueAlias.js';

import successCrtPickList from './successCrtPickList';
import api from '@/api/';
import commonMixin from '@/components/mixin/common_mixin';
import pickingRules from "@/components/common/pickingRules";

export default {
  props: ['countLabels', 'parameterObj'],
  mixins: [commonMixin],
  data() {
    return {
      defaultShow: true,
      createAfterTableData: [],
      stockOutType: [
        {
          code: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000180'),
          value: 'SS'
        }, {
          code: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000179'),
          value: 'MM'
        }, {
          code: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005981'),
          value: 'MIX'
        }
      ],
      disabledCreateBtn: false,
      picking_text: '',
      createPickListModel: false,
      selectTableData: [],
      createType: null,
      clickRowObj: {},
      packageSummaryInfoColumns: [
        {
          title: 'text',
          key: 'text'
        },
        {
          title: 'num',
          key: 'num'
        }
      ],
      packageSummaryInfoData: [],
      pickingRule: {},
      pickingSetInfo: {}
    };
  },
  computed: {
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    },
  },
  methods: {
    // 初始化数据
    initCreatePickListData(selectTableData, createType, row) {
      let v = this;
      v.createType = createType;
      v.clickRowObj = row;
      v.selectTableData = selectTableData;
      // 获取缓存中拣货单规则设置
      let userId = this.userInfo.userId;
      let pickListSetting = localStorage.getItem('pickListSetting' + userId);
      if (pickListSetting) {
        v.pickingSetInfo = JSON.parse(pickListSetting);
      }
      v.getPackageSummaryInfo();
    },
    // 获取当前包裹汇总信息
    getPackageSummaryInfo() {
      let v = this;
      let obj = {
        warehouseId: [v.getWarehouseId()],
        pickingType: ['S1']
      };
      // 勾选
      if (v.createType === 'select') {
        obj.pickingNos = v.selectTableData.map(val => val.pickingNo);
      }
      // 单个
      else if (v.createType === 'single') {
        obj.pickingNos = [this.clickRowObj.pickingNo];
      }
      v.defaultShow = true;
      v.disabledCreateBtn = true;
      v.axios.post(api.post_wmsPicking_querySaleInfomation, obj, {loading: true}).then(res => {
        if (res.data.code === 0) {
          v.createPickListModel = true;
          let data = res.data.datas;
          if (data) {
            v.packageSummaryInfoData = [
              {
                text: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003813') + ':',
                num: data.wmsPickingNumber === null ? 0 : data.wmsPickingNumber
              },
              {
                text: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006076') + ':',
                num: data.orderNumber === null ? 0 : data.orderNumber
              },
              {
                text: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006077') + ':',
                num: data.skuNumber === null ? 0 : data.skuNumber
              },
              {
                text: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002866') + ':',
                num: data.goodsNumber === null ? 0 : data.goodsNumber
              }
            ];
            v.disabledCreateBtn = false;
          }
        } else {
          v.disabledCreateBtn = false;
        }
      });
    },
    // 生成拣货单
    clickBtn() {
      let v = this;
      let obj = {
        ...v.parameterObj,
        ...v.pickingRule,
        pickingType: ['S1'],
        remark: this.picking_text,
      };
      obj.allowDiffMailMode = v.pickingRule.allowDiffLogisticsDealer === '1' ? null : v.pickingRule.allowDiffMailMode;
      // 勾选
      if (v.createType === 'select') {
        obj.pickingNos = v.selectTableData.map(val => {
          return val.pickingNo;
        });
      }
      // 单个
      else if (v.createType === 'single') {
        obj.pickingNos = [this.clickRowObj.pickingNo];
      }
      this.axios.post(api.post_wmsPickingGoods_create, obj, {loading: true, loadingText: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000213')}).then(res => {
        localStorage.setItem('pickListSetting' + v.userInfo.userId, JSON.stringify(v.pickingRule));
        if (res.data.code === 0) {
          v.$Message.success(aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000413'));
          let data = res.data.datas;
          v.$emit('closeSuccess', 'update');
          data.forEach(val1 => {
            v.stockOutType.forEach(val2 => {
              if (val1.packageGoodsType === val2.value) {
                val1.type = val2.code;
              }
            });
          });
          v.createAfterTableData = data;
          v.defaultShow = false;
        }
      });
    },
    // 关闭、取消生成拣货单弹窗
    closeBtn(value) {
      this.$emit('closeSuccess', value);
      this.createAfterTableData = [];
      this.picking_text = '';
      this.createPickListModel = false;
      this.defaultShow = true;
    },
    // 选中常用标签
    selectTag(name) {
      let text = this.picking_text;
      if (text !== '') {
        this.picking_text = text + '，' + name;
      } else {
        this.picking_text = name;
      }
    },
    // 监听弹窗
    createPickListChange(value) {
      if (value) {
        this.defaultShow = true;
      }
    },
    // 获取拣货单生成规则数据
    updateRulesData(data) {
      this.pickingRule = data;
    }
  },
  components: {
    successCrtPickList,
    pickingRules
  },
};
</script>
<style lang="less" scoped>
/deep/ .ivu-modal-body {
  max-height: 650px;
  overflow-y: auto;
}

/deep/ .createPickListStyle {
  .createPickList_item {
    margin-bottom: 15px;

    .title_box {
      font-size: 15px;
      color: #333;
      font-weight: bold;
      margin-bottom: 8px;
    }
  }
}
</style>
